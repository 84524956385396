<template>
    <div class="countdown-subscribe">
        <div class="countdown-subscribe-card" :style="cardStyle">
            <transition name="fade" mode="out-in">
                <div v-if="!showResponse" class="form-wrapper">
                    <rich-text :content="blok.form" />
                </div>
                <div v-else class="response-wrapper">
                    <rich-text :content="blok.response" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ICountdownEmailSubscription } from '~ui/types/components/Countdown/CountdonwEmailSubscription';
import { buildBackground } from '~ui/utils/styleBuilder';
const { blok } = defineProps<{ blok: ICountdownEmailSubscription }>();

const showResponse = ref(false);
const handleSuccess = () => {
    showResponse.value = true;
};

const cardStyle = computed(() => {
    const background = buildBackground(blok.background?.[0]);

    return {
        ...background,
        //shadow style
        boxShadow: blok.shadow ? `0px 8px 16px 0px rgba(43, 46, 49, 0.84)` : null,
    };
});

provide('handleSuccess', handleSuccess);

const { render } = useStoryblokRichText({});
const richText = (content: any) => {
    return render(content.content);
};
</script>

<style scoped lang="postcss">
.countdown-subscribe {
    @apply w-full;
    :deep(.countdown-email-subscription-form) {
        @apply w-full md:w-2/3 mx-auto my-6;
    }
    :deep(.title) {
        @apply font-bold text-base md:text-[21px] uppercase;
    }
    :deep(.code-reveal-button) {
        @apply w-full md:w-[254px] mx-auto w-full mt-6 h-10;
    }
}
.countdown-subscribe-card {
    @apply mx-auto w-full md:w-[82%] bg-[#454549] text-[#BEC1D2] text-center rounded-lg py-10 px-10 text-xs;
}
.form-wrapper {
    @apply w-full;
    :deep(p) {
        @apply text-[10px];
    }
}
.response-wrapper {
    @apply w-full;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
